// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {CRSI5uqc7: {hover: true}};

const cycleOrder = ["CRSI5uqc7"];

const serializationHash = "framer-uwtC8"

const variantClassNames = {CRSI5uqc7: "framer-v-1lbutz7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, G7wWIrK1M: link ?? props.G7wWIrK1M, oOBXxIN8K: title ?? props.oOBXxIN8K ?? "Button Text"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oOBXxIN8K, G7wWIrK1M, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CRSI5uqc7", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={G7wWIrK1M}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1lbutz7", className, classNames)} framer-5dgbpu`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CRSI5uqc7"} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "rgb(255, 255, 255)", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{"CRSI5uqc7-hover": {"--border-color": "rgba(255, 255, 255, 0.1)", backgroundColor: "rgba(255, 255, 255, 0.1)"}}} {...addPropertyOverrides({"CRSI5uqc7-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBOYXJyb3cgTWVkaXVt", "--framer-font-family": "\"Obviously Narrow Medium\", \"Obviously Narrow Medium Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-letter-spacing": "1px", "--framer-line-height": "140%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}>Free Memberships Avalable</motion.p></React.Fragment>} className={"framer-wrxbbs"} fonts={["CUSTOM;Obviously Narrow Medium"]} layoutDependency={layoutDependency} layoutId={"T86_b9B7U"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={oOBXxIN8K} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uwtC8.framer-5dgbpu, .framer-uwtC8 .framer-5dgbpu { display: block; }", ".framer-uwtC8.framer-1lbutz7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 18px 40px 18px 40px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-uwtC8 .framer-wrxbbs { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-uwtC8.framer-1lbutz7 { gap: 0px; } .framer-uwtC8.framer-1lbutz7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-uwtC8.framer-1lbutz7 > :first-child { margin-left: 0px; } .framer-uwtC8.framer-1lbutz7 > :last-child { margin-right: 0px; } }", ".framer-uwtC8[data-border=\"true\"]::after, .framer-uwtC8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 61
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"DFGgk3Clh":{"layout":["auto","auto"]}}}
 * @framerVariables {"oOBXxIN8K":"title","G7wWIrK1M":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroBD7wvzWh: React.ComponentType<Props> = withCSS(Component, css, "framer-uwtC8") as typeof Component;
export default FrameroBD7wvzWh;

FrameroBD7wvzWh.displayName = "V2 / Button 2";

FrameroBD7wvzWh.defaultProps = {height: 61, width: 182};

addPropertyControls(FrameroBD7wvzWh, {oOBXxIN8K: {defaultValue: "Button Text", displayTextArea: false, title: "Title", type: ControlType.String}, G7wWIrK1M: {title: "Link", type: ControlType.Link}} as any)

addFonts(FrameroBD7wvzWh, [{explicitInter: true, fonts: [{family: "Obviously Narrow Medium", source: "custom", url: "https://framerusercontent.com/assets/9hGgksFOrAEveaJKiLPt6HuftY.woff2"}]}], {supportsExplicitInterCodegen: true})